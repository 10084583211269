
@media screen and (min-width: 800px) {

    .footer-wrapper{
        display:flex;
        justify-content:center;
        align-items: center;
        position: absolute;
        bottom: 0px;
        height: 10%;
        width: 100%;
        background-color: #616161bb;
    }
    
    .juntos {
        color: aliceblue;
        font-family: 'Eina', sans-serif;
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 2px;
        margin-left: 20px;
    }
    
    .logo{
        width: 80px;
        height: auto;
        margin: 20px;
    }

    .gabinete-title {
        display: none;
    }

    .homepage-menu-wrapper {
        display: none;
    }
}


@media screen and (max-width: 800px) {
    .homepage {
        display: flex;
        justify-content: center;
    }
   
    .header {
        z-index: 1;
 
    }

    .footer-wrapper{
        display:flex;
        justify-content:center;
        align-items: center;
        position: absolute;
        bottom: 0px;
        height: 10%;
        width: 100%;
    }

    .logo{
        width: 80px;
        height: auto;
        margin: 20px;
    }

    .juntos {
        display: none;

    }

    .gabinete-title{
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: left;
        align-items: center;
        position: absolute;
        top: 0%;
        color: aliceblue;
        font-family: 'Eina', sans-serif;
        background-color: #616161a2;
        font-size: 15px;
        font-weight: 100;
        letter-spacing: 1px;
        padding-left: 20px;
    }

    .homepage-menu-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        position: absolute;
        bottom: 10%;
        width: 100%;
        height: 40px;
        background-color: #616161a2;
    }

    .homepage-menu-vertical{
        list-style-type: none;
        display: block;
        padding-inline-start: 0;
        text-align: center;
        width: 100%; 
    }
    
    .juntos-mobile {
        color:aliceblue;
        text-decoration: none;
        font-family: 'Eina', sans-serif;
        font-size: 15px;
        align-items: center;
    }
    
} 
