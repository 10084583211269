@media screen and (min-width: 800px) {

    .section-wrapper {
        display: flex;
        padding-left: 27px;
        padding-right: 27px;
        flex-wrap: nowrap;
        background-color: rgb(36, 36, 36);
    }

    .column-fixed {
        width: 55%;
        position: sticky;
        margin-top: 100px;
        padding-bottom: 50px;
        margin: 0px 20px;
        display: flex;
        flex-direction: column;
        top: 100px;
        height: fit-content;
    }

    .title1{
        font-family: 'Eina', sans-serif;
        font-size: 2.5rem;
        font-weight: 300;
        line-height: normal;
        margin: 1.2rem 0px;
        transition: all 250ms ease 0s;
        color: #a8a8a8;
    }

    .title2{
        font-family: 'Eina', sans-serif;
        font-size: 2.5rem;
        font-weight: 800;
        line-height: normal;
        margin: 1.2rem 0px;
        transition: all 250ms ease 0s;
        color: #a8a8a8;
    }

    .column-sliding{
        width: 60%;
        padding: 130px 0px 80px;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin: 0px 20px;
    }

    .column-sliding2{
        width: 60%;
        padding: 40px 0px 80px;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin: 0px 20px;
    }

    .row{
        padding: unset;
        display: flex;
        margin-bottom: 20vh;
    }

    .circle{
        display: block;
        padding-top: 8px;
    }

    .circle-structure{
        position: relative;
        display: block;
        color: #f5f5f5;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: rgb(26, 27, 26);
        border-color: rgb(56, 57, 56);
        border: 3px solid rgb(103, 105, 103);
    }

    .subtitle{
        font-family: 'Eina', sans-serif;
        font-weight: bold;
        margin-block-start: 0;
        color: #a8a8a8;;
    }

    .text{
        line-height: 2rem;
        font-family: 'Eina', sans-serif;
        font-weight: 400;
        color: #a8a8a8;
    }
    .text-block{
        padding-left: 15px;
    }

    .section-wrapper2 {
        display: flex;
        padding-left: 27px;
        padding-right: 27px;
        flex-wrap: nowrap;
        background-color: rgb(56, 57, 56);
    }

    .circle-structure2{
        position: relative;
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: rgb(49, 49, 49);
        border: 3px solid rgb(26, 27, 26);
    }

    .section-wrapper3 {
        display: block;
        padding-left: 27px;
        padding-right: 27px;
        justify-content: center;
        align-items: center;
        background-color: rgb(36, 36, 36);
    }

    .section-wrapper4 {
        display: flex;
        padding-left: 27px;
        padding-right: 27px;
        background-color: rgb(44, 44, 44);
    }

    .one-column1 {
        display: flex;
        justify-content: center;
        padding-top: 100px;
    }

    .one-column2 {
        display: flex;
        justify-content: center;
        padding: 60px 0px;
    }

    .flex {
        display: flex;
    }

    .subtitle1 {
        font-family: 'Eina', sans-serif;
        font-weight: 400;
        margin-block-start: 0;
        color: #a8a8a8;
        padding: 0px 0px 20px 20px;
    }

    .bold {
        font-weight: bold;
    }
}

@media screen and (max-width: 800px){

    .section-wrapper {
        display: block;
        padding-left: 27px;
        padding-right: 27px;
        background-color: rgb(36, 36, 36);
    }

    .column-fixed {
        width: 100%;
        padding-top: 30%;
        height: fit-content;
        text-align: center;
    }

    .title1{
        font-family: 'Eina', sans-serif;
        font-size: 20px;
        font-weight: 300;
        line-height: normal;
        margin: 1.2rem 0px;
        transition: all 250ms ease 0s;
        color: #a8a8a8;

    }

    .title2{
        font-family: 'Eina', sans-serif;
        font-size: 20px;
        font-weight: 800;
        line-height: normal;
        margin: 1.2rem 0px;
        transition: all 250ms ease 0s;
        color: #a8a8a8;
    }

    .column-sliding{
        width: 100%;
        padding-top: 20px;
        padding-bottom: 10%;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin: 0px 20px;
    }

    .column-sliding2{
        width: 100%;
        padding: 40px 5px 80px;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: justify;
    }

    .row{
        padding: unset;
        display: flex;
        margin-bottom: 40px;
    }

    .circle{
        display: block;
        padding-top: 8px;
    }

    .circle-structure{
        position: relative;
        display: block;
        color: #f5f5f5;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: rgb(26, 27, 26);
        border-color: rgb(56, 57, 56);
        border: 3px solid rgb(103, 105, 103);
    }

    .subtitle{
        font-family: 'Eina', sans-serif;
        font-size: 15px;
        font-weight: bold;
        margin-block-start: 0;
        color: #a8a8a8;;
    }

    .text{
        line-height: 2rem;
        font-family: 'Eina', sans-serif;
        font-weight: 400;
        color: #a8a8a8;
    }
    .text-block{
        padding-left: 15px;
    }

    .section-wrapper2 {
        display: block;
        padding-left: 27px;
        padding-right: 27px;
        background-color: rgb(56, 57, 56);
    }

    .circle-structure2{
        position: relative;
        display: block;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: rgb(49, 49, 49);
        border: 3px solid rgb(26, 27, 26);
    }

    .section-wrapper3 {
        display: block;
        padding-left: 27px;
        padding-right: 27px;
        justify-content: center;
        align-items: center;
        background-color: rgb(36, 36, 36);
    }

    .section-wrapper4 {
        display: block;
        padding-left: 27px;
        padding-right: 27px;
        background-color: rgb(44, 44, 44);
    }

    .one-column1 {
        display: flex;
        justify-content: center;
        padding-top: 100px;
        text-align: center;
    }

    .one-column2 {
        display: flex;
        justify-content: center;
        padding: 60px 0px;
    }

    .flex {
        display: flex;
    }

    .subtitle1 {
        font-family: 'Eina', sans-serif;
        font-size: 15px;
        font-weight: 400;
        margin-block-start: 0;
        color: #a8a8a8;
        padding: 0px 0px 20px 20px;
    }

    .bold {
        font-weight: bold;
    }
    
}