* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

.menu-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

}

.menu-trigger {
  background: #616161bb;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  height: 10%;


}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.068);

}

.menu-trigger span {
  font-weight: 700;
  vertical-align: middle;
  font-size: 14px;
  margin: 0 10px;
}

.menu-trigger img {
  width: 25px;
  height: 25px;
}

.menu {
  background: #6e6e6e;
  border-radius: 12px;
  position: absolute;
  top: 50px;
  right: 0;
  width: max-content;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.menu li:hover{
  background-color: #5b5b5b;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  z-index: 1;
}

.menu ul {
  list-style: none;
  padding: 0;
  display: block;
  justify-content: center;
}

.menu li {
  justify-content: center;
}

.menu li a {
  text-decoration: none;
  padding: 15px 20px;
  display: block;
  text-align: center;
  color: aliceblue;

}

.menu li a.active{
  background-color: #5b5b5b;
}

  
