.homepage-menu{
    list-style-type: none;
    display: flex;
    padding-inline-start: 0;
    text-align: center;
    background-color: #616161; /* Black background color */
    position: fixed; /* Make it stick/fixed */
    top: 0; /* Stay on top */
    width: 100%; /* Full width */
    transition: top 0.3s; /* Transition effect when sliding down (and up) */
    z-index: 1; /* Stay in first plane */
    justify-content: center;
    margin: 0;
}

.homepage-menu>li{
    display: flex;
    text-align: center;
}

.homepage-menu>li>a {
    color:#dadada;
    font-family: 'Eina', sans-serif;
    font-weight: 600;
    padding: 10px 25px;
    text-decoration: none;
}

.homepage-menu>li>a:hover{
    color:#ffffff;
    text-decoration: underline;
    text-underline-offset: 12px;
}

.secundary-menu{
    display: none;
}

.active a{
    background-color:#6e6e6e;
}


@media screen and (max-width: 800px ){

    .menu-wrapper {
        display: none;
    }

    .header {
        min-width: 14rem;
    }

    .menu {
        padding-bottom: 7px;
    }

    .secundary-menu {
        display: flex;
        position: absolute;
        top: 0px;
        right: 0px;
        height: 10%;
        width: 10%;
        justify-content: center;
        align-items: center;
        padding-right: 8%;
    }
    
    .active a {
        padding-bottom: 5px;
    }

}